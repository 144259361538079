import News from '@/model/news';

export default {
	state: {
		news_list: [],
		news: News,
	},

	getters: {
		news_list: state => state.news_list,
		news: state => state.news,
	},

	mutations: {
		SetNewsList(state, news_list){
			state.news_list = news_list;
		},

		SetNews(state, news){
			state.news = news;
		}
	},
}
